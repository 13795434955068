.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: default;
  border: 2px solid #ddd;
  color: black; /* Set the default number color to black */
  margin-left: 6px;
  margin-right: 6px;
}

.normal {
  background-color: #fff;
}

.activeStyle {
  background-color: #007bff;
  color: white; /* Set the number color to white in active circles */
}

.hover {
  background-color: #007bff;
  color: white;
}
