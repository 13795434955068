.workout-container {
  text-align: center;
  padding: 20px;
  /* background-color: #f0f0f0; */
  transition: zoom 0.3s ease;
}

.workout-header h1 {
  margin: 0;
  font-size: 1.5em;
}

.workout-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 20px 0;
}

.workout-header p {
  color: #555;
}

.exercise-list {
  display: flex;
  /* flex-wrap: wrap;
  justify-content: space-around; */
  margin: 0 0 20px 0;
}

.exercise-item {
  cursor: pointer;
  width: 300px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.exercise-item:hover {
  transform: scale(1.05);
}

.exercise-image {
  /* width: 100%; */
  /* height: 200px; */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.exercise-content {
  padding: 20px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.exercise-content h2 {
  color: #4285f4;
}

.exercise-content p {
  color: #555;
}

/* .workout-card {
  margin-top: 20px;
} */

.workout-image,
.exercise-image {
  width: 100%;
  /* height: auto; */
}

.zoom-controls {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}
.zoom-progress {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.workout-card {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(10px); /* Adjust the blur amount as needed */
}

.blurred-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    255,
    255,
    255,
    0.3
  ); /* Adjust the background color and opacity */
}
.exercise-card {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px !important;
  background-color: #f7f7f7 !important;
  padding-bottom: 2px;
  padding-top: 2px;
}
.base-card {
  /* border-radius: 5px !important; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  /* background-color: #e5e5e5 !important; */
}
